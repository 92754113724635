import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FileManagerService} from '../../services/file-manager.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NgForm} from '@angular/forms';
import {FileObject} from '../../classes/folder';

export const WORKER = [
    'css',
    'html',
    'javascript',
    'json',
    'php',
    'xml',
    'lua'
];

@Component({
    selector: 'ftm-file',
    templateUrl: './file.component.html',
    styleUrls: ['./file.component.scss']
})

export class FileComponent implements OnInit {
    public validate = false;
    public permission: string;
    public fileContent: any = '';

    @ViewChild(NgForm, {static: true}) public form: NgForm;
    public file: FileObject;

    constructor(
        private _fmService: FileManagerService,
        private _snackBar: MatSnackBar, private _router: Router,
        private _translate: TranslateService) {
    }

    @Input('file') set handleFile(items) {
        this.file = items;
        this.permission = this.file.getPermission();

        WORKER.forEach(w => {
            if (this.file.type === w) {
                this.validate = true;
            }
        });

        this._fmService.readFile(this.file.path)
            .subscribe({
                next: res => this.fileContent = res,
                error: () => this.showSnackBar(this._translate.instant('file_manager.error_reading_file'))
            });
    }

    ngOnInit() {
    }

    save() {
        if (this.form.valid) {
            this._fmService.saveFileContent(this.file, this.fileContent)
                .subscribe({
                    next: () => this.showSnackBar(this._translate.instant('file_manager.saved_success')),
                    error: err => this.showSnackBar(this._translate.instant(`file_manager.${err.error.description}`))
                });
        } else {
            this.showSnackBar(this._translate.instant('file_manager.saved_error'));
        }
    }

    showSnackBar(msg) {
        this._snackBar.open(msg);
    }

    cancel() {
        this._router.navigate(this._fmService.rootUrl, {queryParams: {path: btoa(this.file.dir)}}).then();
    }

}
